import * as React from 'react'
import { navigate } from 'gatsby'
import JobSidebar from '../../components/jobSidebar'
import SEO from '../../components/seo/seo'
import ChargesTable from '../../components/settings/charges'
import MonthlyChargesTable from '../../components/settings/monthlyCharges'
import ContractorSidebar from '../../components/contractorSidebar'
import { useUserRoleQuery, UserRole, useUserBilledMonthlyQuery } from '../../apollo/generated'
import Loading from '../../components/ui/Loading';

const ChargesPage = () => {
	const { data, loading: loadingUserRole, error } = useUserRoleQuery();
	const {
		data: { user: userBilledMonthly } = { user: undefined },
		loading: loadingBilledMonthly,
	} = useUserBilledMonthlyQuery()

	if (loadingUserRole || loadingBilledMonthly) { return <Loading /> }
	if (error) { return <div>Error: {error.message}</div>; }
	if (!data || !data.user || !data.user.id) {
		navigate('/');
		return null;
	}

	const { user: { role } } = data;

	if (role === UserRole.Homeowner) {
		return (
			<JobSidebar>
				<SEO title="Billing History" keywords={['3d', 'construction']} />
				{userBilledMonthly ? <MonthlyChargesTable /> : <ChargesTable />}
			</JobSidebar>
		)
	} else if (role === UserRole.Contractor) {
		return (
			<ContractorSidebar>
				<SEO title="Billing History" keywords={['3d', 'construction']} />
				<ChargesTable />
			</ContractorSidebar>
		);
	} else {
		return <ChargesTable />
	}
}

export default ChargesPage;
